import React from 'react'
import { Contact } from '../../components/pages/contact'
import { useSiteMetadata } from '../../hooks/useSiteMetadata'

const ENContactPage = (props: any) => {
  const siteMetadata = useSiteMetadata()
  return <Contact {...props} en={true} siteMetadata={siteMetadata} />
}

export default ENContactPage
